import { inject, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { BreakPointService } from './break-point.service';

export const IS_XS = new InjectionToken<Observable<boolean>>(
  'isHandset as stream',
  {
    providedIn: 'root',
    factory: () => inject(BreakPointService).isXs$,
  },
);

export const IS_XL = new InjectionToken<Observable<boolean>>(
  'is xl as stream',
  {
    providedIn: 'root',
    factory: () => inject(BreakPointService).gtLg$,
  },
);

export const IS_2XL = new InjectionToken<Observable<boolean>>(
  'is 2xl as stream',
  {
    providedIn: 'root',
    factory: () => inject(BreakPointService).isFullWidth$,
  },
);
